const UrlName = '/modular/admin/v1'
import URL from '../../config/url';

export default API => ({
    /**
     *
     * 获取配置项
     */
    getConfigurationNew(data = {}) {
      const domain = URL.confUrl;
      return API({ url: `/modular/admin/v1/configurations/${domain}`, data });
  },

    getConfiguration() {
      let url = URL.confUrl;
        return API({ 
          url: `${UrlName}/configurations/${url}`
        });
    },
    //业务端获取PageJson
    getPageJson(){
      const params = {};
      params.domain = URL.confUrl;
      return API({ 
        url: `${UrlName}/block/getPageConfig`, 
        params: params,
        method: 'POST',
      });
    },
})
 