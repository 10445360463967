import Vue from 'vue'
import Router from 'vue-router'
import api from './../api'
import Axios from 'axios'
import md5 from 'js-md5'
// import api from '@/api'
import routes from './routers'
import conf from '../config/url.js'
import store from '../store/index'
// import sensors from '../utils/sensors'
Vue.use(Router)

const router = new Router({
	mode: 'history',
	routes,
})

let frontendConfig = null;
const setWebsiteConfig = async () => {
	// 获取配置参数【主题，页面配置】
	if (!frontendConfig) {
		await api.configure.configurationNew(conf.confUrl).then((res) => {
			if (res.data) {
				frontendConfig = res.data.frontendConfig.filter((item) => {
					if (item.key === 'homepage') {
						return true
					}
				})[0]
				// 获取h5域名
				let h5_address = res.data.frontendConfig.find((item) => item.key === 'h5_address')
				if (h5_address && h5_address.value) {
					localStorage.setItem('domain', JSON.stringify([{ domain: h5_address.value, frontend_template_id: 4 }]))
				} else if (res.data.solutionInfo && res.data.solutionInfo.domain) {
					localStorage.setItem('domain', JSON.stringify(res.data.solutionInfo.domain) ? JSON.stringify(res.data.solutionInfo.domain) : '');
				}
				localStorage.setItem('theme', res.data.theme || '')
				localStorage.setItem('frontendConfig', frontendConfig ? JSON.stringify(frontendConfig) : '')
				localStorage.setItem('hasGetConfig', '1')
				localStorage.setItem('configurationArr', JSON.stringify(res.data.frontendConfig))
				if (res.data.websiteSetting && res.data.websiteSetting.pageLogo) {
					let link = document.querySelector("link[rel*='icon']")
					link.href = res.data.websiteSetting.pageLogo
					localStorage.setItem('pageLogo', res.data.websiteSetting.pageLogo)
				}
				if (res.data.websiteSetting && res.data.websiteSetting.pageTitle) {
					document.title = res.data.websiteSetting.pageTitle
					localStorage.setItem('pageTitle', res.data.websiteSetting.pageTitle)
				}
			}
		})
	} else {
		if (localStorage.getItem('pageTitle')) {
			document.title = localStorage.getItem('pageTitle')
		}
		if (localStorage.getItem('pageLogo')) {
			let link = document.querySelector("link[rel*='icon']")
			link.href = localStorage.getItem('pageLogo')
		}
	}
}
 setWebsiteConfig()
// 大平台导航头部
const navName = ['learning', 'assessList', 'examList', 'courseGoods', 'aic_homepage', 'aic_about', 'liveList']

// let isNewSaasConfig, isNewSaas
// let isFirst = true //第一次获取配置标识
router.beforeEach(async (to, from, next) => {
	if (localStorage.getItem('pageTitle')) {
		document.title = localStorage.getItem('pageTitle')
	}
	// 如果携带token自动处理后续登录逻辑
	if (to.query.token) {
		// 保证登录最新的账号
		store.commit('logout')
		localStorage.setItem('token', to.query.token)
		await afterLogin(to.query.isAutoRegister)
	}

	// 一键实施默认登录
	if (to.path === '/demo_homepage') {
		if (!localStorage.getItem('token')) {
			await api.usercenter.demoLogin({}).then((res) => {
				if (res.data) {
					localStorage.setItem('token', res.data)
				}
			})
			// 选择机构
			try {
				await getFindUserBranchList()
			} catch (error) {
				if (error) {
					localStorage.clear()
					return false
				}
			}
			// 获取账号信息
			getAccountUserInfo()
			getUserInfo()
		}
	}

	//   解决导航头选中问题
	if (!navName.includes(to.name)) {
		to.meta.highlight = from.meta.highlight
	}

	if (to.path !== '/exam/testPaper') {
		sessionStorage.removeItem('submissionTime')
		sessionStorage.removeItem('secondsExam')
		sessionStorage.removeItem('allowCommitDurationExam')
	}

	// // 是否用户中心改造
	// if (isFirst && !isNewSaasConfig) {
	// 	await api.configure.configurationNew(conf.confUrl).then((res) => {
	// 		if (res.data) {
	// 			isNewSaasConfig = res.data.frontendConfig.find((item) => item.key === 'isNewSaas')
	// 			// console.log('first----------------------------', store.state.dynamic.frontendConfig, isNewSaasConfig)
	// 			isFirst = false
	// 			if (isNewSaasConfig) {
	// 				if (isNewSaasConfig.value === 'true') {
	//                     isNewSaas = true
	//                     store.commit('setSaasFlag', true)
	// 				}
	// 			}
	// 		}
	// 	})
	// }




	// console.log(store.state.dynamic.frontendConfig, 'store.state.dynamic.frontendConfig')
	const token = localStorage.getItem('token')
	// if (frontendConfig && frontendConfig?.key == 'homepage' && to.path.replace('/', '') === frontendConfig?.key) {
	//   let is_track_single_page = localStorage.getItem('is_track_single_page')
	//   is_track_single_page == 'true' ? is_track_single_page = true : is_track_single_page = false
	//   !is_track_single_page && sensors.track('wdpageview')
	// }
	if (token && (to.path === '/login' || to.path === '/login_new')) {
		// if(isNewSaas && !localStorage.getItem('identity')){//用户中心改造需要选择身份才能跳转
		//     next('/login_new')
		// }
		//已经登陆仍然访问login时，直接去首页
		setTimeout(() => {
			const frontendConfig = store.state.dynamic.frontendConfig.filter((item) => {
				if (item.key === 'homepage') {
					return true
				}
			})
			if (frontendConfig.length && frontendConfig[0].value) {
				next('/' + frontendConfig[0].value || 'learning')
			}
		}, 600)
	} else if (token && to.path == '/') {
		// if(isNewSaas && !localStorage.getItem('identity')){//用户中心改造需要选择身份才能跳转
		//     next('/login_new')
		// }
		setTimeout(() => {
			let path = '/learning'
			let frontendConfig = store.state.dynamic.frontendConfig.filter((item) => {
				if (item.key === 'homepage') {
					return true
				}
			})
			if (frontendConfig[0].key == 'homepage') {
				if (frontendConfig.length && frontendConfig[0].value) {
					console.log(frontendConfig[0].value, 'frontendConfig[0].valuevaluevalue++++')
					path = '/' + frontendConfig[0].value
				}
			}
			next({
				path: path,
			})
		}, 600)
	} else if (!token && to.path == '/') {
		// console.log(store.state.dynamic.frontendConfig, '+++')
		setTimeout(() => {
			let path = '/login'
			// if (isNewSaas) {
			// 	path = '/login_new'
			// }
			const frontendConfig = store.state.dynamic.frontendConfig.filter((item) => {
				if (item.key === 'homepage') {
					return true
				}
			})
			if (frontendConfig[0].key == 'homepage') {
				if (frontendConfig.length && frontendConfig[0].value) {
					path = '/' + frontendConfig[0].value
				}
			}
			next({
				path: path,
			})
		}, 600)
	} else if (!token && to.meta.login) {
		let path = '/login'
		// if (isNewSaas) {
		// 	path = '/login_new'
		// }
		next({
			path,
		})
	} else {
		if (to.path === '/login') {
			// if (isNewSaas) {
			// 	next('/login_new')
			// }
		}
		next()
	}
})

router.afterEach(() => {
	window.scrollTo(0, 0)
})

// 是否登录到指定机构
function is_login_current_branch() {
	let is_login_current_branch = store.state.dynamic.frontendConfig.find((item) => item.key === 'is_login_current_branch')
	if (is_login_current_branch) {
		if (is_login_current_branch.value === 'true') {
			return true
		}
	}
	return false
}
// // 查询用户id
// function findUserId() {
// 	api.usercenter.findUser().then((res) => {
// 		if (res.code == 0) {
// 			let subUser = {
// 				id: res.data.id
// 			}
// 			localStorage.setItem('subUserInfo', JSON.stringify(subUser))
// 		}
// 	})
// }

// 登录后续逻辑
async function afterLogin(isAutoRegister) {
	console.log('执行了登录后续');
	const loginToCurrent = is_login_current_branch()
	let branchId, branchUcode
	if (loginToCurrent) { //是否登录到指定的机构
		branchId = localStorage.getItem('branchId')
		branchUcode = localStorage.getItem('branchUcode')
		if (!branchId || !branchUcode) {
			let res = await api.configuration.getConfigurationNew()
			branchId = res.data.branchId
			branchUcode = res.data.branchUcode
			localStorage.setItem('branchId', branchId)
		}
	}
	if (!localStorage.getItem('token').includes('ss_')) {
		// 获取机构信息
		return Axios({
			baseURL: conf.API,
			url: '/usercenter/v1/account/findUserBranchList',
			headers: {
				token: localStorage.getItem('token')
			}
		}).then(res => {
			const branchRes = res.data
			if (branchRes.success) {
				if (branchRes.data && branchRes.data.length) {
					let currentBranch = branchRes.data[0]
					console.log('loginToCurrent', loginToCurrent)
					if (loginToCurrent) { //登录到指定机构
						currentBranch = branchRes.data.find((item) => {
							let branch = md5(branchId + item.token).toUpperCase()
							return item.branch === branch
						})
					}
					if (currentBranch) {
						localStorage.setItem('code', currentBranch.code)
						localStorage.setItem('organ', currentBranch.organ)
						localStorage.setItem('actor', currentBranch.actor)
						localStorage.setItem('branch', currentBranch.branch)
						currentBranch.channel && localStorage.setItem('channel', currentBranch.channel)
						// // 获取用户信息
						// getUserInfo()
						// // 获取用户id
						// findUserId()
						// 获取账号信息
						getAccountUserInfo()
						getUserInfo()
						console.log('登录成功')
					} else {
						if (isAutoRegister) {
							// return accountJoinBranch(branchUcode)
						} else {
							this.$message.warning('暂未加入当前机构')
						}
					}
				} else {
					if (isAutoRegister) {
						//   return accountJoinBranch(branchUcode)
					}
				}
			} else if (branchRes.code === 303) {
				if (isAutoRegister) {
					// return accountJoinBranch(branchUcode)
				} else {
					this.$message.warning(branchRes.message)
				}
			} else {
				this.$message.warning(branchRes.message)
			}
		}).catch(error => {
			console.log(error, '出错了-------------------')
		})
	}

}

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch((err) => err)
}

// 获取所在机构
function getFindUserBranchList() {
	return api.usercenter.getFindUserBranchList({}).then((res) => {
		if (res.data && res.data.length) {
			const branchList = res.data
				.filter((item) => Object.prototype.toString.call(item) === '[object Object]')
				.map((item) => {
					return {
						name: item.name,
						id: item.branch,
						actor: item.actor,
						organ: item.organ,
						userId: item.userId,
						code: item.code,
					}
				})
			if (branchList.length) {
				localStorage.setItem('branchList', JSON.stringify(branchList))
				localStorage.setItem('code', branchList[0].code)
				localStorage.setItem('organ', branchList[0].organ)
				localStorage.setItem('actor', branchList[0].actor)
				localStorage.setItem('branch', branchList[0].id)
				localStorage.setItem('userId', branchList[0].userId)
			}
		} else {
			return Promise.reject(res.message)
		}
	})
}

// 获取账号信息
function getAccountUserInfo() {
	api.usercenter.getAccountUserInfo({}).then((res) => {
		store.commit('saveUserInfo', res.data)
		localStorage.setItem('userInfo', JSON.stringify(res.data))
	})
}

function getUserInfo() {
	api.usercenter.findUser().then((res) => {
		if (res.code == 0) {
			store.commit('saveSubUserInfo', res.data)
			localStorage.setItem('subUserInfo', JSON.stringify(res.data))
		}
	})
}

export default router
