<template>
    <div class="exam-list" v-loading="loading1">
        <!-- 产品 李波 确认去掉 -->
        <!-- <div class="tab">
            <p class="text" v-for="tab in tabList" :key="tab.id" :class="{active:tabIndex === tab.id}" >
                {{tab.value}}
            </p>
        </div> -->
        <div class="content">
            <div class="list examList" v-for="item in examList" :key="item.id">
                <div class="left item">
                    <img class="cover" :src="item.coverUrl ? item.coverUrl : require('../../assets/images/common/exam-placeholder-img.png')" alt="">
                    <img v-show="item.showNew" class="icon" src="../../assets/images/learning/new-icon.png" alt="">
                </div>
                <div class="middle item">
                    <div class="title">
                        <svg-icon v-if="frontendConfig == 'true' " :icon-class="ConstData.useType[item.examType].icon" :class="ConstData.useType[item.examType].icon"></svg-icon>
                        <h1>{{item.examName}}</h1>
                    </div>
                    <p class="exam-time">
                        <svg-icon icon-class="exam_time" class="exam_icon"></svg-icon>
                        <span class="exam_info" v-if="item.isLimitTime==1">
                            考试时间：{{item.startTime.replace(/-/g,'.')}} - {{item.endTime.replace(/-/g,'.')}}
                        </span>
                        <span class="exam_info" v-else-if="item.isLimitTime==2">
                            考试时间：每月定期（{{item.regularDate}}）
                        </span>
                        <span class="exam_info" v-else>
                            考试时间：长期有效
                        </span>
                    </p>
                    <p class="source">
                        <svg-icon icon-class="exam_source" class="exam_icon"></svg-icon>
                        <span class="exam_info">
                            {{$t('WDNPC_WEB_SOURCE',{text:'来源：'})}}{{item.sourceName}}
                        </span>
                    </p>
                </div>
                <div class="right item">
                    <div class="button" @click="goExam(item)">
                        <svg-icon icon-class="lock" v-show="item.showLock"></svg-icon>
                        {{item.buttonStatus === 1? $t('WDNPC_WEB_START_EXAM',{text:'开始考试'}) : item.buttonStatus === 2 ? $t('WDNPC_WEB_REEXAMINATION',{text:'重考'}) :$t('WDNPC_WEB_VIEW',{text:'查看'})}}
                    </div>
                </div>
            </div>
            <article v-if="noData" class="no-data">
                <img class="notraining" src="../../assets/images/common/nodata.png" alt />
                <p>{{$t('WDNPC_WEB_YOU_HAVENT_LEARNED_THE_CONTENT_YET',{text:'您还没有学习内容'})}}</p>
            </article>
            <p class="loadingmore" v-if="loading">{{$t('WDNPC_WEB_LOADING',{text:'加载中'})}}...</p>
            <p class="loadingmore" v-if="noMore">- {{$t('WDNPC_WEB_ALL_CONTENT_LOADED',{text:'已加载全部内容'})}} -</p>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import SDK from '@wd/lowcode-pc'
export default {
    name:'examList',
    data(){
        return {
            frontendConfig:false,
            loading1: true,
            tabIndex:1,
            tabList:[
                {
                    id:1,
                    value:this.$t('WDNPC_WEB_ALL',{text:'全部'}),
                }
            ],
            examList:[],
            paging: {
                params: {
                    pageNum: 1,
                    pageSize: 10
                },
                total: 0
            },
            loading:false,
            noData:null
        }
    },
    created(){
       let frontendConfigList =  SDK.getConfigruation(this.$store).frontendConfig;
       frontendConfigList.map(item=>{
           if(item.key === 'isShowexamTag'){
               console.log('item---->', item)
               this.frontendConfig = item.value;
           }
       })
        console.log("0000000---->",SDK.getConfigruation(this.$store))
    },
    mounted(){
        this.getData()
        $(window).scroll(() => {
            //判断是否滑动到页面底部
            if (
                Math.round($(window).scrollTop()) ===
                $(document).height() - $(window).height()
            ) {
                // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
                // console.log('页面底部')
                
                setTimeout(this.reachBottom(), 1000)
            }
        });
    },
    computed: {
        noMore () {
            return this.examList.length === this.paging.total && this.paging.total !== 0
        },
    },
    methods:{
        // tabClick(item){
        //     this.tabIndex = item.id
        // }
        goExam(item){
            if(item.showLock){
                if(item.lockCourseRate){
                    this.$message.warning({
                        message:this.$t('WDNPC_WEB_IF_THE_EXAM_IS_NOT_ENABLED_THE',{text:'该考试未开启，开启条件为前面的课程学习进度达到'})+item.lockCourseRate+'%',
                        offset:70
                    })
                }
                if(item.lockExamCondition){
                    this.$message.warning({
                        message:this.$t('WDNPC_WEB_IF_THE_EXAM_IS_NOT_OPENED_THE',{text:'该考试未开启，开启条件为前面的考试得分及格'}),
                        offset:120
                    })
                }
            }else {
                if(item.isLimitTime==2){
                    this.$message.warning({
                        message:'该考试未到开启时间'
                    })
                    return
                }
                this.$router.push({
                    path:'/examDetails',
                    query:{
                        bussinessId:item.bussinessId,
                        examId:item.examId,
                        type:item.sourceType,
                        title:item.sourceName,
                        pathName:this.$route.path,
                        backMoudle:'exam',
                        backPath:this.$route.path,
                        // WDNPC_WEB_LONG_TERM_EFFECTIVE 长期有效
                        timeSlot:item.isLimitTime,
                        startTime:item.startTime,
                        endTime:item.endTime
                    }
                })
            }
        },
        reachBottom() {
            if (this.paging.total > this.paging.params.pageNum * this.paging.params.pageSize) {
                this.paging.params.pageNum = this.paging.params.pageNum + 1;
                this.loading = true;
                this.getData("reachBottom");
            } else {
                this.loading = false;
            }
        },
        getData(){
            const params = {
                pageNum: this.paging.params.pageNum,
                pageSize: this.paging.params.pageSize,
            }
            this.$api.learning.findUserExams({params}).then(({ data: { list, total } }) => {
                this.loading1 = false;
                if(list && list.length){
                    this.noData = false
                }else {
                    this.noData = true
                }
                if(this.paging.params.pageNum>1){
                    this.examList = this.examList.concat(list);
                } else {
                    this.examList = list;
                }
                this.paging.total = total;
            });
        }
    }
}
</script>
<style lang="stylus" scoped>
@import './../../assets/css/learningList.styl'
</style>
